.accounts__actionbar__container {
    margin-top: 4rem;
}

.accounts__actionbar.container {
    padding: 0;
    text-align: right;
}

.accounts__actionbar > * + * {
    margin-left: 2rem;
}

li.account__overview__item {
    padding: 0.75rem 1rem;
    display: grid;
    grid-template-columns: 1fr 13px;
    align-content: center;
}

li.account__overview__item input {
    top: 50%;
    transform: translateY(-50%);
    margin: 0;
    height: 1rem;
    width: 1rem;
}

ul.accounts__overview__container {
    margin-top: 2rem;
    border-radius: 5px;
    overflow:hidden;
    box-shadow:0px 0px 80px -20px var(--shadow-color);
}

li.account__overview__item a {
    color: var(--text-color);
    display: block;
}

li.account__overview__item + li.account__overview__item {
    border-top: 2px solid var(--outline);
}

li.account__overview__item:nth-child(even){
    background-color: var(--background-accent);
}
