.app__inner.lead__detail {
    transform: translate(0, 0);
	transition:0.2s ease-out;
    grid-template-columns: auto max(800px, calc(50% - var(--gap) / 2));
}

.app__inner.lead__detail.is__parsing {
    transform: translate(-50%, 0);
}

.app__inner.grid.two.lead__detail.is__parsing.parser {grid-template-columns: auto calc(50% - var(--gap) / 2);}

.lead__row  span.col.name a {color: #fff;}


.lead__container:not(.lead__row) {margin: auto;width: 100%;max-width: 800px;padding: 2rem;box-shadow: 0px 0px 30px 0px var(--shadow-color);border-radius: 8px;}
.single__lead.lead__container {padding: 0;margin: 0;max-width: none;box-shadow: none;border-radius: 0;}

.single__lead > span {display: grid !important;grid-template-columns: 50% 50%;border: 1px solid var(--outline); padding: 0.75rem 1rem;border-radius: 10px;margin: 0 0 1rem !important;}

.single__lead span.col.options {background-color: transparent; position: absolute;bottom: 100%;    right: -1rem;
    margin-bottom: 2.3rem !important;
    border: none;}

.lead__container > h2 {top: -0.6rem;margin-bottom: 1rem;display: flex;}

.lead__container > h2 button.i__edit {padding: 1rem; margin: auto 0 auto 1rem; 
    background-color: transparent;
    border: 2px solid #000;
    filter: invert(1);}

.single__lead label {font-weight: bold; text-align: left;}

.single__lead > span:nth-child(even) {background-color: var(--background-accent);}

.single__lead span.col.points {grid-template-columns: 50% 2rem 4rem 2rem;align-items: center;text-align: center; justify-content: left !important;}

.lead__container button.i__edit.active {background-color: var(--accent); filter: invert(0);}

.single__lead textarea {padding: 0.5rem;font-family: var(--font);resize: none;min-height: 125px; outline:none;}

span.col.comment button {grid-column: 2;padding: 0.25rem;border-radius: 0 0 5px 5px !important;margin-top: -1px;background-color: #f3f3f3;}

.single__lead select {border: none;background-color: transparent;}

span.col.comment button.active {background-color: #9be37b;}



span.col.rejected button.rejection__button {margin: auto auto auto 0;}

.rejection__button:active {transform: none;}

span.col.rejected button.rejection__button:not(.no__edit) {
    outline: 1px solid var(--outline);padding: 0.5rem;box-shadow: inset 0px 0px 10px 0px var(--shadow-color);
}

.lead__container > .return__link {margin-top: 0;margin-bottom: 2rem;position: absolute;margin-left: -2rem;bottom: 100%;}

.single__lead.hot__lead::before {height: 4rem;width: 4rem;top: 100%;left: 100%;margin: 1rem 0rem;}
.single__lead.hot__lead span.col.name {padding-left: 1rem;}
.single__lead select option {background-color: var(--background-color);}





.lead__detail  .lead__profile__container .return__link {padding: 0;margin-left: auto;margin-right: auto;}




.social__selling__container > h2 {margin: 0rem 0 2rem;text-align: center;}

.social__selling__container > h3 {margin-top: -1rem !important;margin-bottom: 2rem;text-align: center;font-size: 1.5rem !important;}
.social__selling__controls {display: grid; grid-template-columns: 1fr 1fr 1fr; justify-content: space-between;}
.social__selling__controls span.previous__lead, .social__selling__controls span.next__lead { cursor: pointer; top:0.5rem;}
.social__selling__controls span.previous__lead {
    grid-column: 1;
} 
.social__selling__controls span.next__lead { grid-column: 3; text-align: right;}

.social__selling__controls button { text-align: center; grid-column:2; }


.social__selling__container .lead__container.change {animation: popIn 0.6s ease-out forwards;}

a.ss__to__overview {display: block;text-align: center;margin-top: -1rem;}

@keyframes popIn {
    0% { transform: scale(0.75); opacity:0; }
    30% { transform: scale(0.95); opacity: 1; }
    100% { transform: scale(1); opacity: 1; }
}
