.calendar__days,
.calendar__body {
    display: grid;
    grid-template-columns: 2rem 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    position: relative;
}

.calendar__day {
    cursor: default;
}

.calendar__day,
.calendar__item {
    position: relative;
    display: block;
    border: 2px solid #3a3a3aa6;
    margin-bottom: -2px;
    margin-left: -2px;
}

.calendar__day::before {
    content: '';
    padding: 50%;
    display: block;
}

.calendar__day>span,
.calendar__item .calendar__date {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.calendar__days {
    background-color: var(--background-color);
}

.calendar__item::before {
    content: '';
    padding: 50%;
    padding: calc(calc(100vh - 220px) / 2 / 10);
    padding: 50%;
    display: block;
}

.calendar__item {
    cursor: pointer;
    background-color: var(--background-accent);
    transition: 0.2s ease-in-out;
}

.calendar__container {
    white-space: nowrap;
    height: 100%;
    background-color: var(--background-color);
    border-radius: 8px;
    border: 1px solid var(--outline);
    padding: 1rem;
    overflow: hidden;
}

.calendar__view {
    max-width: 400px;
    border-radius: 8px;
    background-color: var(--background-accent);
    padding: 1rem;
    display: inline-block;
    width: 100%;
    transition: 0.2s ease-out;
}

.calendar__item:hover {
    background-color: var(--background-color);
}

.calendar__item.active {
    background-color: var(--accent);
    transition: 0.1s ease-in-out;
}

.calendar__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

button.calendar__previous {
    background: url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTMuNzg5IDcuMTU1Yy4xNDEtLjEwOC4zLS4xNTcuNDU2LS4xNTcuMzg5IDAgLjc1NS4zMDYuNzU1Ljc0OXY4LjUwMWMwIC40NDUtLjM2Ny43NS0uNzU1Ljc1LS4xNTcgMC0uMzE2LS4wNS0uNDU3LS4xNTktMS41NTQtMS4yMDMtNC4xOTktMy4yNTItNS40OTgtNC4yNTgtLjE4NC0uMTQyLS4yOS0uMzYtLjI5LS41OTIgMC0uMjMuMTA3LS40NDkuMjkxLS41OTEgMS4yOTktMS4wMDIgMy45NDUtMy4wNDQgNS40OTgtNC4yNDN6Ii8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: invert(1);
    border: none;
    transition: 0.1s ease-in-out;
    padding: 1.25rem 1rem;
}

button.calendar__next {
    background: url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMTMuNzg5IDcuMTU1Yy4xNDEtLjEwOC4zLS4xNTcuNDU2LS4xNTcuMzg5IDAgLjc1NS4zMDYuNzU1Ljc0OXY4LjUwMWMwIC40NDUtLjM2Ny43NS0uNzU1Ljc1LS4xNTcgMC0uMzE2LS4wNS0uNDU3LS4xNTktMS41NTQtMS4yMDMtNC4xOTktMy4yNTItNS40OTgtNC4yNTgtLjE4NC0uMTQyLS4yOS0uMzYtLjI5LS41OTIgMC0uMjMuMTA3LS40NDkuMjkxLS41OTEgMS4yOTktMS4wMDIgMy45NDUtMy4wNDQgNS40OTgtNC4yNDN6Ii8+PC9zdmc+);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    filter: invert(1);
    border: none;
    transform: rotateY(180deg);
    transition: 0.1s ease-in-out;
    padding: 1.25rem 1rem;
}

button.calendar__previous:active {
    transform: scale(0.8);
}

button.calendar__next:active {
    transform: rotateY(180deg) scale(0.8);
}

button.calendar__previous[disabled],
button.calendar__next[disabled] {
    cursor: not-allowed;
    visibility: hidden;
}

.calendar__date.empty {
    position: relative;
    display: block;
    border: 2px solid #3a3a3aa6;
    margin-bottom: -2px;
    margin-left: -2px;
    background-color: var(--background-accent);
    opacity: 0.5;
    cursor: default;
}

article.content__planning__form h2:first-child {
    margin: 1rem auto 0.5rem;
}

.calendar__view+.calendar__view {
    margin-left: 10%;
}

.calendar__view.active {
    z-index: 1;
    background-color: #22222a;
    left: 0 !important;
    margin-left: 0 !important;
    position: relative !important;
}

/* .calendar__container::before {
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    width: 10%;
    display: block;
    position: absolute;
    z-index: 1;
}

.calendar__container.drag__scroll::after {
    content: '';
    display: block;
    top: 50%;
    right: 0;
    height: 0vh;
    width: 0px;
    transform: translate(50%, -50%);
    position: absolute;
    box-shadow: 9vw 1rem 7vw 25vh #000000;
    opacity: 0.8;
} */

article.content__planning__form h2.calendar__month {
    line-height: 1 !important;
    font-size: 1.2rem !important;
    margin: auto;
}


.calendar__container>* {
    transform: translateX(220%);
}


.calendar__container .calendar__view.active+.calendar__view {
    transform: translateX(100%);
}

.calendar__container {
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.calendar__month__navigation {
    transform: inherit;
    display: flex;
    justify-content: space-between;
    padding: 0rem 0 2rem;
    position: absolute;
    top: 1.25rem;
    left: 2rem;
    right: 2rem;
    z-index: 10;
}

.calendar__item.pre__plannend::after,
.calendar__item.has__item::after {
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    position: absolute;
    background: var(--accent);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgOWwtMi41MTkgNC0yLjQ4MS0xLjk2LTUgNi45NmgxNmwtNi05em04LTV2MTZoLTIwdi0xNmgyMHptMi0yaC0yNHYyMGgyNHYtMjB6bS0yMCA2YzAtMS4xMDQuODk2LTIgMi0yczIgLjg5NiAyIDJjMCAxLjEwNS0uODk2IDItMiAycy0yLS44OTUtMi0yeiIvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-size: 66%;
    background-position: center;
    display: block;
    z-index: 1;
    border-radius: 50% 50% 0% 50%;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    background-color: #fdcb6f73;
    box-shadow: inset 0 0 0 2px var(--accent);
}

.calendar__item.has__item::after {
    background-color: var(--accent);
    box-shadow: none;
}

.post__item>* {
    width: 100%;
    padding: 0.5rem 0.75rem;
    margin-bottom: 1rem;
    line-height: 2;
    outline: 1px solid #ffffff24;
    border: none;
    resize: none;
    border-radius: 5px;
}

button.context__menu::before {
    content: '';
    height: 8px;
    width: 8px;
    display: block;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 -11px 0px 0px #fff, 0 11px 0px 0px #fff;
}

button.context__menu {
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 5px;
    padding: 1rem 1.2rem;
    transform: scale(0.5) translate(50%, 25%);
    background: none;
    width: auto;
    outline: none;
    z-index: 2;
    transform: 0.2s ease-out;
}

.calendar__view {
    margin: 0 0% !important;
}

.calendar__view.active+.calendar__view {
    margin: 0 10% !important;
}



.done.calendar__item::after {
    background-color: #b1e02e;
    filter: invert(1);
}