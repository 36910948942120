nav.nav__container {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    color: var(--shadow-color);
    z-index: 99999999999999999;
}

.nav__inner.container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 70px;
}

.nav__inner figure {
    width: auto;
    flex: auto 0 0;
    transform: translateY(15%);
    height: 150%;
    position: absolute;
}

.nav__inner figure img {
    width: auto;
    object-fit: contain;
    max-width: 170px;
}

.nav__inner ul {
    flex: auto 1 1;
    justify-content: end;
    display: flex;
    margin: auto 0;
    line-height: 1;
}

.nav__inner ul li {
    padding: 0.25rem 0.5rem;
    margin: auto 0;
}

nav a {
    text-decoration: none;
    color: #ffffff;
}

button.logout {
    padding: 0.5rem 1rem;
    background-color: #ffffffb5;
    color: #000;
}