.container.zd__lln__user__container {margin-top: 4rem;}

.zd__lln__login__main, .zd__lln__login__secondary {
    padding: 1rem;
    max-width: 500px;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 var(--shadow-color);
}

form label {
    display: block;
    /* font-weight: bold; */
    margin-top: 1rem;
}

form input, .input__field {
    width: 100%;
    padding: 1rem;
    font-size: 16px;
    border: none;
    border-radius: 10px;
}

.input__field {
    margin-bottom: 1rem;
}

.zd__lln__login__main {
    padding: 2rem;
}

.zd__lln__login__secondary {
    margin-top: 1rem;
}

.zd__lln__login__main a {
    margin: 1rem 0;
    display: block;
}

.zd__lln__login__main input[type="submit"] {margin-top: 2rem;}