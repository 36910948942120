@media screen and (max-width: 1300px){
    .app__inner.lead__detail { overflow-y: auto;height: auto;display: block;}
        
    .leads__inner.container {overflow: hidden;height: auto;}    
        
    .leads__container, .parser__container, .posts__container {height: auto;}
        
    button.add__new__lead {order: -1;position: sticky;top: 2rem;left: inherit;max-width: 350px;margin: 0 auto;width: 100%;}
        
    .lead__view__container {overflow: initial;}
        
    .mode__controler.container {margin-bottom: 2rem;}

    .lead__overview__head {display: none;}



li.lead__overview__item {grid-template-columns: 1fr;padding-right: 3rem;}

.leads__display__container button.rejection__button {position: absolute;top: 1rem;right: 1rem;}

li.lead__overview__item span {
    display: grid;
grid-template-columns: calc(60px + 1rem) auto;
grid-gap: 1rem; padding:0.4rem 0}

li.lead__overview__item span::before {display: inline-block;font-weight: bold;}

li.lead__overview__item span:nth-child(1)::before {content: 'Functie';}

li.lead__overview__item span:nth-child(2)::before {content: 'Bedrijf';}

li.lead__overview__item span:nth-child(3)::before {content: 'Locatie';}

.posts__inner.container {overflow: initial !important; height: auto !important;}



div.parse__result__container {position: relative;left: inherit;width: 100%;height: auto;margin-top: var(--gap);}

    div.parse__result__container .parser__container {height: 100%;border-radius: 20px !important;height: 300px;margin-bottom: 2rem;}
        
    div.parse__result__container .parser__container .parser__inner.container {box-shadow: inset 0 0 2rem 0 var(--shadow-color);}

    .parser__container>h2 {margin-top: -2.5rem;}

    div.parse__result__container .parser__container > h2 {margin-top: 0;}

    .lead__row.lead__container {    
        grid-template-columns: 1fr 1fr 1fr;
    }

    

    .parse__view__container, .parse__result__container, .posts__view__container {
        padding: 0 var(--padding);
    }
    
    .account__settings__container.container {margin-bottom: 2rem;}
}

@media screen and (max-width: 768px){
    
    :root {--padding: 5%;}

    .lead__row.lead__container {
        grid-template-areas: "name name name flag options"
        "points status status track track";
    }
    
    .col > select {width: 100%;}
    
    .col.track {grid-area: track;}
    
    .col.status {grid-area: status;}
    
    .col.name {grid-area: name;}
    
    .col.points {grid-area: points;}
    
    span.col.options {grid-area: options;}
    
    span.col.rejection__botton {grid-area: flag;}

    .single__lead>span {grid-template-columns: 1fr;}
    .single__lead>span { grid-gap:1rem; }

    .single__lead>span.comment > * {grid-column: 1 !important;}

    

    .card__inner {grid-template-columns: 1fr;justify-items: center;}

    figure.card__icon {width: 100%;}

    .card__information {text-align: center;}

    .parser__container, .leads__container, .posts__container {
        padding: 2rem min(2rem, var(--padding));
        border-radius: 20px !important;
        outline: 1px solid var(--outline);
    }


    .lead__filters {grid-template-columns: 1fr;}

    .lead__filters input[type="text"] {grid-column: 1;}

    .post__pop__up__container {position: fixed;
        top: 4rem;
        bottom: 0;}

    .posts__view__container.create__new *, .lead__view__container.create__new * { animation: none; transform: none; }

    .pop__up__inner {margin: 5rem auto auto;}

    .app__inner.grid.two.lead__detail.leads {transform: unset;}

    #root > .lead__container {margin-top: 9rem !important;}

    .lead__container > .return__link {margin-left: 0;}

    .lead__profile__container .return__link {
        padding-left: var(--padding)
    }


    .post__row .col {grid-area: unset !important;}

}