:root {
	--text-color: #ffffff;
	--background-gradient: linear-gradient(45deg, #ffffff14 40%, #ffffff05);
	--background-color: #0f1019a6;
	--background-accent: #0f10195e;
	--shadow-color: #0d0d0d75;

	--accent: #f6bb31;
	--secondary: #ca9e4e;
	--outline: #4a4a4fa6;

	--success: #91cc49;
	--error: #cc4949;

	--font: "Open sans";
	font-size: 16px;
	--font-alt: "Open sans";
	--heading: "Montserrat", "sans-serif";
	--text-margin: 2rem;
	--max-container-size: 1600px;
}

#root {
	overflow-y: auto;
}

html,
body {
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: #000;
}

* {
	font-size: 1rem;
	color: var(--text-color);
}


button,
input[type="submit"],
.button {
	background: linear-gradient(0deg, var(--accent) 60%, var(--secondary));
	border: none;
	cursor: pointer;
	color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--text-color);
	line-height: 1.2 !important;
}

h1+h1,
h1+h2,
h1+h3,
h1+h4,
h1+h5,
h1+h6,
h1+p,
h1+ul,
h1+ol,
h1+blockquote,
h1+.button,
h2+h1,
h2+h2,
h2+h3,
h2+h4,
h2+h5,
h2+h6,
h2+p,
h2+ul,
h2+ol,
h2+blockquote,
h2+.button,
h3+h1,
h3+h2,
h3+h3,
h3+h4,
h3+h5,
h3+h6,
h3+p,
h3+ul,
h3+ol,
h3+blockquote,
h3+.button,
h4+h1,
h4+h2,
h4+h3,
h4+h4,
h4+h5,
h4+h6,
h4+p,
h4+ul,
h4+ol,
h4+blockquote,
h4+.button,
h5+h1,
h5+h2,
h5+h3,
h5+h4,
h5+h5,
h5+h6,
h5+p,
h5+ul,
h5+ol,
h5+blockquote,
h5+.button,
h6+h1,
h6+h2,
h6+h3,
h6+h4,
h6+h5,
h6+h6,
h6+p,
h6+ul,
h6+ol,
h6+blockquote,
h6+.button,
p+h1,
p+h2,
p+h3,
p+h4,
p+h5,
p+h6,
p+p,
p+ul,
p+ol,
p+blockquote,
p+.button,
ul+h1,
ul+h2,
ul+h3,
ul+h4,
ul+h5,
ul+h6,
ul+p,
ul+ul,
ul+ol,
ul+blockquote,
ul+.button,
ol+h1,
ol+h2,
ol+h3,
ol+h4,
ol+h5,
ol+h6,
ol+p,
ol+ul,
ol+ol,
ol+blockquote,
ol+.button,
blockquote+h1,
blockquote+h2,
blockquote+h3,
blockquote+h4,
blockquote+h5,
blockquote+h6,
blockquote+p,
blockquote+ul,
blockquote+ol,
blockquote+blockquote,
blockquote+.button,
.button+h1,
.button+h2,
.button+h3,
.button+h4,
.button+h5,
.button+h6,
.button+p,
.button+ul,
.button+ol,
.button+blockquote,
.button+.button {
	margin-bottom: 0;
}

input,
select,
textarea {
	outline: #4a4a4fa6 solid 1px;
	background-color: var(--background-color);
	font-family: var(--font);
}

button {
	padding: 0.75rem 1rem;
	background-color: #ffffff;
	border: 1px solid #2c2c2c4b;
	font-size: 16px;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.2s ease-out;
}

button:active {
	transform: scale(0.95);
}

button+button {
	margin-left: 1rem;
}

select {
	padding: 0.5rem;
	outline: none;
}

#root {
	height: 100%;
	width: 100%;
	display: flex;
	padding-top: 70px;
	overflow-x: hidden;
	overflow-y: auto;
}

a {
	text-decoration: none;
	color: var(--accent);
}

.app__inner {
	width: 100%;
}

.loading__screen::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: block;
	background-color: #fff;
	z-index: 10;
	opacity: 0.4;
	animation: fadeIn 0.3s ease-out forwards;
}

.loading__screen::after {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	width: 0.5rem;
	height: 0.5rem;
	margin-left: -2rem;
	margin-top: -2rem;
	z-index: 11;
	animation: fadeIn 0.3s ease-out forwards, rotate 1s ease-in-out infinite;
	box-shadow: 0px 0px 0px 0.5rem transparent, -1rem -1rem 0px 0.5rem #000,
		1rem -1rem 0px 0.5rem #000, -1rem 1rem 0 0.5rem #000,
		1rem 1rem 0 0.5rem #000;
}

a.return__link {
	margin-top: 3rem;
	display: inline-block;
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 0.8;
	}
}

@keyframes rotate {
	from {
		transform: rotate(-45deg);
	}

	to {
		transform: rotate(315deg);
	}
}

@keyframes moveFromRight {
	0% {
		transform: translateX(calc(100% - 2rem));
	}

	100% {
		transform: translateX(0%);
	}
}

.hide {
	visibility: hidden;
}

.inactive {
	opacity: 0.4;
	cursor: not-allowed;
}