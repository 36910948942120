.card__container.container {
    z-index: 1;
    padding-top: 70px;

}

.card__inner {
    max-width: 500px;
    min-height: 200px;
    border-radius: 10px;
    background: var(--background-gradient);
    border: 1px solid var(--outline);
    box-shadow: 0px 0px 50px 0 var(--shadow-color);
    padding: 2rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: min(150px, 40%) auto;
    grid-gap: 2rem;
    overflow: hidden;
}

figure.card__icon {
    flex: 40% 0 0;
    border-radius: 50%;
    background-color: #efefef;
    max-width: 150px;
    max-height: 150px;
    margin-bottom: auto;
}

figure.card__icon::before {
    content: '';
    padding-top: 100%;
    display: block;
}

.card__information {
    margin: auto 0;
}

.card__information h2 {
    font-size: var(--h5) !important;
}

.card__information a {
    margin-top: 1rem;
    text-decoration: none;
    background-color: var(--accent);
    display: inline-block;
    padding: 0.75rem 1rem;
    line-height: 1;
    border-radius: 5px;
    color: #000;
    display: block;
    text-align: center;
}