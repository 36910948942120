.message__container{
    margin: auto;
    width: 100%;
    max-width: 800px;
    padding: 2rem;
    box-shadow: 0px 0px 30px 0px var(--shadow-color);
    border-radius: 8px;
}

.message__item {padding: 0 1.5rem;}

.message__container > .return__link {margin-top: 0;margin-bottom: 2rem;position: absolute;margin-left: -2rem;bottom: 100%;}

.message__container > h2 {top: -0.6rem;margin-bottom: 1rem;display: flex;}

.message__inner {    
    overflow: hidden auto;
    max-height: 60vh;
    margin-bottom: 2rem;
    box-shadow: inset 0px 0px 20px var(--background-color);
    border-radius: 8px;
}

.message__item + .message__item {
    border-top: 2px solid var(--outline);
}
.message__item:nth-child(even) {
    background-color: var(--background-accent);
}

.message__item > h2 {
    font-size: 1rem !important;
    padding: 1rem 0;
    cursor: pointer;
}

.message__preview {
    max-height: 0;
    overflow: hidden; 
    transition: 0.5s ease;
    padding: 0 1rem;
    background-color: var(--background-accent);
    white-space: break-spaces;
}

.message__inner > .i__add {margin-top: 1rem;}

.active > .message__preview {
    max-height: 350px;
    padding: 1rem 4rem 1rem 1rem;
}

.active.message__item {  padding: 0 1.5rem 1.5rem; }

.active.message__item > h2::before {transform: translateY(-50%) rotate(45deg);}

.message__item > h2::before {content: '';position: absolute;top: 50%;right: 0;transform: translateY(-50%) rotate(-135deg);width: 0.5rem;height: 0.5rem;border-left: 2px solid;border-top: 2px solid;}

.message__preview > button { transition: 0.2s ease-out; position: absolute;right: 1rem;bottom: 50%; transform: translateY(50%);background-color: transparent;filter: invert(1);border: none;}
.active .message__preview > button { bottom: 1rem; transform: translateY(0%); }


.message__builder__preview {white-space: break-spaces;
    box-shadow: inset 0 0 16px 0 var(--background-color);
    padding: 1rem;
    border-radius: 8px;}

    .message__builder {display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;background-color: var(--background-accent);padding: 2rem;border-radius: 8px;}

.message__builder .input__container {flex: 100%;}

.message__builder > * {flex: 48% 0 0;}

.app__inner.social__selling {padding: 0; display: flex;flex-direction: row;justify-content: space-between;align-items: center;}

.app__inner.social__selling > * {flex: 48% 0 0;}

/* .message__builder .input__container .input__field {margin-bottom: 0;} */