.content__planning__container>h2 {
    font-size: 1.8rem !important;
    margin-top: 1rem;
    margin-bottom: -1rem;
}

.zd__content__planning {
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0.5rem;
    border: 1px solid #000000;
    margin: 0 0 0.5rem;
    cursor: pointer;
}

.zd__content__planning>a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    z-index: 2;
}

.zd__content__planning:last-child {
    margin-bottom: 0;
}

.zd__content__planning:nth-child(even) {
    background-color: var(--background-accent);
}

.zd__content__planning>h2 {
    font-size: 1rem !important;
    margin-right: auto;
}

article.zd__content__planning .zd__date__container {
    font-size: 0.8rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 1rem;
}

.content__planning__container .return__link {
    padding-top: 2rem;
}

.planning__overview__container {
    margin-top: 2rem;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0px 0px 80px -20px var(--shadow-color);
}

span.zd__icon.deadline::before {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMGMtNi42MjcgMC0xMiA1LjM3My0xMiAxMnM1LjM3MyAxMiAxMiAxMiAxMi01LjM3MyAxMi0xMi01LjM3My0xMi0xMi0xMnptNyAxNGgtOHYtOWgydjdoNnYyeiIvPjwvc3ZnPg==');
    background-size: contain;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    filter: invert(1);
    vertical-align: middle;
    margin-right: 0.5rem;
    position: relative;
    top: -2px;
}

.date__passed {
    color: var(--error);
    font-weight: bold;
}

.date__approaching {
    color: var(--accent);
    font-weight: bold;
}

span.zd__icon.feedback::before {
    content: '';
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjIgM3YyMWgtMjB2LTIxaDQuNjY3bC0yLjY2NyAyLjgwOHYxNi4xOTJoMTZ2LTE2LjE5MmwtMi42MDktMi44MDhoNC42MDl6bS0zLjY0NiA0bC0zLjMxMi0zLjU2OXYtLjQxYy4wMDEtMS42NjgtMS4zNTItMy4wMjEtMy4wMjEtMy4wMjEtMS42NjcgMC0zLjAyMSAxLjMzMi0zLjAyMSAzbC4wMDEuNDMxLTMuMjk4IDMuNTY5aDEyLjY1MXptLTYuMzU0LTVjLjU1MiAwIDEgLjQ0OCAxIDFzLS40NDggMS0xIDEtMS0uNDQ4LTEtMSAuNDQ4LTEgMS0xem0tNSAxNWgxMHYxaC0xMHYtMXptMC0xaDEwdi0xaC0xMHYxem0wLTJoMTB2LTFoLTEwdjF6bTAtMmgxMHYtMWgtMTB2MXoiLz48L3N2Zz4=');
    background-size: contain;
    width: 1rem;
    height: 1rem;
    display: inline-block;
    filter: invert(1);
    vertical-align: middle;
    margin-right: 0.5rem;
    position: relative;
    top: -2px;
}

span.zd__icon.feedback.one::after,
span.zd__icon.feedback.two::after {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-weight: bold;
    content: '1';
    display: block;
    font-size: 18px;
    color: var(--accent);
    height: 0rem;
    width: 2rem;
    padding: 1rem 0;
    text-align: center;
    line-height: 0;
    border-radius: 50%;
    background-color: #2c2d35;
    transform: translate(-55%, -35%) scale(0.5);
}


span.zd__icon.feedback.one::after {
    content: '1';
}

span.zd__icon.feedback.two::after {
    content: '2';
}

span.zd__icon {
    position: relative;
}


.checkbox__emulator,
.social__channels label {
    display: block;
    margin-left: auto;
}

.checkbox__emulator::after {
    content: '';
    height: 0.5rem;
    width: 0.5rem;
    border: 1px solid var(--accent);
    display: inline-block;
    border-radius: 2px;
    margin: auto 0 auto auto;
}

.social__channels {
    display: flex;
    flex-direction: column;
}

.social__channels .checkbox__emulator,
.social__channels label {
    min-width: 100px;
    display: flex;
}

.social__channels label input {
    margin-left: auto;
}

.zd__date__container>* {
    margin-right: 2rem;
}

.zd__content__planning__detail .zd__date__container {
    margin: 1rem 0;
}

article.zd__content__planning__detail {
    padding: 2rem 3rem;
}

.zd__post__details {
    display: flex;
    flex-direction: column;
}

.input {
    padding: 1rem;
    background-color: var(--background-accent);
    border-radius: 10px;
    margin: 1rem 0 0;
}

.input::before {
    content: '';
    border: 8px solid transparent;
    border-bottom-color: var(--background-accent);
    display: block;
    position: absolute;
    bottom: 100%;
    left: 0.25rem;
}

.zd__post__details span>strong {
    margin-right: 0.5rem;
}

.zd__planning__container>.two {
    grid-template-columns: auto 500px;
}

.description>strong {
    font-size: 1.4rem;
    display: block;
    margin-bottom: 1rem;
}

.zd__planning__container .zd__column h2 {
    font-size: 1.4rem !important;
    margin-bottom: 1rem;
}

.zd__planning__container .description {
    margin-top: 1rem;
}

.zd__social__image img {
    width: 100%;
    height: auto;
    object-fit: contain;
    margin-top: 1rem;
}

.zd__social {
    background-color: var(--background-accent);
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid var(--background-color);
    margin: 1rem 0 2rem;
}

.zd__social__head.grid.two {
    grid-template-columns: 50px auto;
    grid-gap: 0.5rem;
}

span.zd__social__logo {
    height: 50px;
    display: block;
}

.zd__social__details>strong,
.zd__social__details>span {
    display: block;
    font-size: 0.7rem;
}

.zd__social__details>strong {
    font-size: 0.8rem;
}

span.zd__social__logo {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgOWwtMi41MTkgNC0yLjQ4MS0xLjk2LTUgNi45NmgxNmwtNi05em04LTV2MTZoLTIwdi0xNmgyMHptMi0yaC0yNHYyMGgyNHYtMjB6bS0yMCA2YzAtMS4xMDQuODk2LTIgMi0yczIgLjg5NiAyIDJjMCAxLjEwNS0uODk2IDItMiAycy0yLS44OTUtMi0yeiIvPjwvc3ZnPg==);
    background-size: contain;
    filter: invert(1);
}

span.show__more {
    display: block;
    text-align: right;
    color: rgb(139 139 139 / 60%);
    font-size: 0.9rem;
    margin-bottom: 1.25rem;
}

.zd__edit__description {
    font-size: 0.9rem;
    white-space: pre-line;
    width: 100%;
    resize: none;
    border: none;
    background-color: transparent;
    outline: none;
    height: auto;
    min-height: 1rem;
    line-height: 2;
    margin: 1rem 0;
    overflow-y: hidden;
}

.zd__edit__description:focus {
    outline: 1px solid;
}


.zd__edit__description.limit {
    max-height: 86px;
    overflow: hidden;
}

.zd__edit__description.limit:focus {
    max-height: unset;
}

.zd__edit__description.limit:focus+.show__more {
    visibility: hidden;
}

.zd__social__image {
    margin: 0 -0.75rem;
    line-height: 0;
}

.zd__social__actions {
    display: flex;
    margin-top: 0.75rem;
}

span.zd__social__like,
span.zd__social__comment,
span.zd__social__repost,
span.zd__social__send {
    line-height: 1;
    padding: 7px 0.75rem 7px 2rem;
    font-size: 0.9rem;
    border-radius: 5px;
    margin-left: 1rem;
}

.zd__social__actions .zd__social__logo {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 1rem;
    margin-left: 1rem;

}

span.zd__social__like::before,
span.zd__social__comment::before,
span.zd__social__repost::before,
span.zd__social__send::before {
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    display: block;
    filter: invert(1);
    background-size: contain;
    transform: rotateY(180deg) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0.5rem;
    margin-right: 2rem;
}

span.zd__social__like:hover,
span.zd__social__comment:hover,
span.zd__social__repost:hover,
span.zd__social__send:hover {
    background-color: #ffffff38;
}

span.zd__social__like::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNDMgOC44MTRjLjgwOC0zLjI4MyAxLjI1Mi04LjgxNC0yLjE5Ny04LjgxNC0xLjg2MSAwLTIuMzUgMS42NjgtMi44MzMgMy4zMjktMS45NzEgNi43ODgtNS4zMTQgNy4zNDItOC40IDcuNzQzdjkuOTI4YzMuNTAzIDAgNS41ODQuNzI5IDguMTY5IDEuODQyIDEuMjU3LjU0MSAzLjA1MyAxLjE1OCA1LjMzNiAxLjE1OCAyLjUzOCAwIDQuMjk1LS45OTcgNS4wMDktMy42ODYuNS0xLjg3NyAxLjQ4Ni03LjI1IDEuNDg2LTguMjUgMC0xLjY0OS0xLjE2OC0yLjQ0Ni0yLjU5NC0yLjUwNy0xLjIxLS4wNTEtMi44Ny0uMjc3LTMuOTc2LS43NDN6bTMuNzE4IDQuMzIxbC0xLjM5NC4xNjdzLS42MDkgMS4xMDkuMTQxIDEuMTE1YzAgMCAuMjAxLjAxIDEuMDY5LS4wMjcgMS4wODItLjA0NiAxLjA1MSAxLjQ2OS4wMDQgMS41NjNsLTEuNzYxLjA5OWMtLjczNC4wOTQtLjY1NiAxLjIwMy4xNDEgMS4xNzIgMCAwIC42ODYtLjAxNyAxLjE0My0uMDQxIDEuMDY4LS4wNTYgMS4wMTYgMS40MjkuMDQgMS41NTEtLjQyNC4wNTMtMS43NDUuMTE1LTEuNzQ1LjExNS0uODExLjA3Mi0uNzA2IDEuMjM1LjEwOSAxLjE0MWwuNzcxLS4wMzFjLjgyMi0uMDc0IDEuMDAzLjgyNS0uMjkyIDEuNjYxLTEuNTY3Ljg4MS00LjY4NS4xMzEtNi40MTYtLjYxNC0yLjIzOC0uOTY1LTQuNDM3LTEuOTM0LTYuOTU4LTIuMDA2di02YzMuMjYzLS43NDkgNi4zMjktMi4yNTQgOC4zMjEtOS4xMTMuODk4LTMuMDkyIDEuNjc5LTEuOTMxIDEuNjc5LjU3NCAwIDIuMDcxLS40OSAzLjc4Ni0uOTIxIDUuNTMzIDEuMDYxLjU0MyAzLjM3MSAxLjQwMiA2LjEyIDEuNTU2IDEuMDU1LjA1OSAxLjAyNSAxLjQ1NS0uMDUxIDEuNTg1eiIvPjwvc3ZnPg==);
}

span.zd__social__comment::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNDMgOC44MTRjLjgwOC0zLjI4MyAxLjI1Mi04LjgxNC0yLjE5Ny04LjgxNC0xLjg2MSAwLTIuMzUgMS42NjgtMi44MzMgMy4zMjktMS45NzEgNi43ODgtNS4zMTQgNy4zNDItOC40IDcuNzQzdjkuOTI4YzMuNTAzIDAgNS41ODQuNzI5IDguMTY5IDEuODQyIDEuMjU3LjU0MSAzLjA1MyAxLjE1OCA1LjMzNiAxLjE1OCAyLjUzOCAwIDQuMjk1LS45OTcgNS4wMDktMy42ODYuNS0xLjg3NyAxLjQ4Ni03LjI1IDEuNDg2LTguMjUgMC0xLjY0OS0xLjE2OC0yLjQ0Ni0yLjU5NC0yLjUwNy0xLjIxLS4wNTEtMi44Ny0uMjc3LTMuOTc2LS43NDN6bTMuNzE4IDQuMzIxbC0xLjM5NC4xNjdzLS42MDkgMS4xMDkuMTQxIDEuMTE1YzAgMCAuMjAxLjAxIDEuMDY5LS4wMjcgMS4wODItLjA0NiAxLjA1MSAxLjQ2OS4wMDQgMS41NjNsLTEuNzYxLjA5OWMtLjczNC4wOTQtLjY1NiAxLjIwMy4xNDEgMS4xNzIgMCAwIC42ODYtLjAxNyAxLjE0My0uMDQxIDEuMDY4LS4wNTYgMS4wMTYgMS40MjkuMDQgMS41NTEtLjQyNC4wNTMtMS43NDUuMTE1LTEuNzQ1LjExNS0uODExLjA3Mi0uNzA2IDEuMjM1LjEwOSAxLjE0MWwuNzcxLS4wMzFjLjgyMi0uMDc0IDEuMDAzLjgyNS0uMjkyIDEuNjYxLTEuNTY3Ljg4MS00LjY4NS4xMzEtNi40MTYtLjYxNC0yLjIzOC0uOTY1LTQuNDM3LTEuOTM0LTYuOTU4LTIuMDA2di02YzMuMjYzLS43NDkgNi4zMjktMi4yNTQgOC4zMjEtOS4xMTMuODk4LTMuMDkyIDEuNjc5LTEuOTMxIDEuNjc5LjU3NCAwIDIuMDcxLS40OSAzLjc4Ni0uOTIxIDUuNTMzIDEuMDYxLjU0MyAzLjM3MSAxLjQwMiA2LjEyIDEuNTU2IDEuMDU1LjA1OSAxLjAyNSAxLjQ1NS0uMDUxIDEuNTg1eiIvPjwvc3ZnPg==);
}

span.zd__social__repost::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNDMgOC44MTRjLjgwOC0zLjI4MyAxLjI1Mi04LjgxNC0yLjE5Ny04LjgxNC0xLjg2MSAwLTIuMzUgMS42NjgtMi44MzMgMy4zMjktMS45NzEgNi43ODgtNS4zMTQgNy4zNDItOC40IDcuNzQzdjkuOTI4YzMuNTAzIDAgNS41ODQuNzI5IDguMTY5IDEuODQyIDEuMjU3LjU0MSAzLjA1MyAxLjE1OCA1LjMzNiAxLjE1OCAyLjUzOCAwIDQuMjk1LS45OTcgNS4wMDktMy42ODYuNS0xLjg3NyAxLjQ4Ni03LjI1IDEuNDg2LTguMjUgMC0xLjY0OS0xLjE2OC0yLjQ0Ni0yLjU5NC0yLjUwNy0xLjIxLS4wNTEtMi44Ny0uMjc3LTMuOTc2LS43NDN6bTMuNzE4IDQuMzIxbC0xLjM5NC4xNjdzLS42MDkgMS4xMDkuMTQxIDEuMTE1YzAgMCAuMjAxLjAxIDEuMDY5LS4wMjcgMS4wODItLjA0NiAxLjA1MSAxLjQ2OS4wMDQgMS41NjNsLTEuNzYxLjA5OWMtLjczNC4wOTQtLjY1NiAxLjIwMy4xNDEgMS4xNzIgMCAwIC42ODYtLjAxNyAxLjE0My0uMDQxIDEuMDY4LS4wNTYgMS4wMTYgMS40MjkuMDQgMS41NTEtLjQyNC4wNTMtMS43NDUuMTE1LTEuNzQ1LjExNS0uODExLjA3Mi0uNzA2IDEuMjM1LjEwOSAxLjE0MWwuNzcxLS4wMzFjLjgyMi0uMDc0IDEuMDAzLjgyNS0uMjkyIDEuNjYxLTEuNTY3Ljg4MS00LjY4NS4xMzEtNi40MTYtLjYxNC0yLjIzOC0uOTY1LTQuNDM3LTEuOTM0LTYuOTU4LTIuMDA2di02YzMuMjYzLS43NDkgNi4zMjktMi4yNTQgOC4zMjEtOS4xMTMuODk4LTMuMDkyIDEuNjc5LTEuOTMxIDEuNjc5LjU3NCAwIDIuMDcxLS40OSAzLjc4Ni0uOTIxIDUuNTMzIDEuMDYxLjU0MyAzLjM3MSAxLjQwMiA2LjEyIDEuNTU2IDEuMDU1LjA1OSAxLjAyNSAxLjQ1NS0uMDUxIDEuNTg1eiIvPjwvc3ZnPg==);
}

span.zd__social__send::before {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNDMgOC44MTRjLjgwOC0zLjI4MyAxLjI1Mi04LjgxNC0yLjE5Ny04LjgxNC0xLjg2MSAwLTIuMzUgMS42NjgtMi44MzMgMy4zMjktMS45NzEgNi43ODgtNS4zMTQgNy4zNDItOC40IDcuNzQzdjkuOTI4YzMuNTAzIDAgNS41ODQuNzI5IDguMTY5IDEuODQyIDEuMjU3LjU0MSAzLjA1MyAxLjE1OCA1LjMzNiAxLjE1OCAyLjUzOCAwIDQuMjk1LS45OTcgNS4wMDktMy42ODYuNS0xLjg3NyAxLjQ4Ni03LjI1IDEuNDg2LTguMjUgMC0xLjY0OS0xLjE2OC0yLjQ0Ni0yLjU5NC0yLjUwNy0xLjIxLS4wNTEtMi44Ny0uMjc3LTMuOTc2LS43NDN6bTMuNzE4IDQuMzIxbC0xLjM5NC4xNjdzLS42MDkgMS4xMDkuMTQxIDEuMTE1YzAgMCAuMjAxLjAxIDEuMDY5LS4wMjcgMS4wODItLjA0NiAxLjA1MSAxLjQ2OS4wMDQgMS41NjNsLTEuNzYxLjA5OWMtLjczNC4wOTQtLjY1NiAxLjIwMy4xNDEgMS4xNzIgMCAwIC42ODYtLjAxNyAxLjE0My0uMDQxIDEuMDY4LS4wNTYgMS4wMTYgMS40MjkuMDQgMS41NTEtLjQyNC4wNTMtMS43NDUuMTE1LTEuNzQ1LjExNS0uODExLjA3Mi0uNzA2IDEuMjM1LjEwOSAxLjE0MWwuNzcxLS4wMzFjLjgyMi0uMDc0IDEuMDAzLjgyNS0uMjkyIDEuNjYxLTEuNTY3Ljg4MS00LjY4NS4xMzEtNi40MTYtLjYxNC0yLjIzOC0uOTY1LTQuNDM3LTEuOTM0LTYuOTU4LTIuMDA2di02YzMuMjYzLS43NDkgNi4zMjktMi4yNTQgOC4zMjEtOS4xMTMuODk4LTMuMDkyIDEuNjc5LTEuOTMxIDEuNjc5LjU3NCAwIDIuMDcxLS40OSAzLjc4Ni0uOTIxIDUuNTMzIDEuMDYxLjU0MyAzLjM3MSAxLjQwMiA2LjEyIDEuNTU2IDEuMDU1LjA1OSAxLjAyNSAxLjQ1NS0uMDUxIDEuNTg1eiIvPjwvc3ZnPg==);
}

.zd__edit__description.empty::before {
    content: 'Omschrijving invoeren...';
    opacity: 0.4;
}

.zd__social__description {
    line-height: 0;
}

.planning__overview__actions {
    padding: 0rem 3rem 2rem;
    text-align: right;
}

.planning__overview__actions .button {
    padding: 0.75rem 1rem;
}

.checkbox__emulator.active::after {
    background: var(--accent);
}


.day__select,
.week__toggle {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    text-align: center;
    vertical-align: middle;
    line-height: 0.5;
    border-radius: 5px;
    border: 2px solid var(--background-color);
    background-color: var(--background-accent);
    margin-right: auto;
    width: auto;
    max-width: 555px;
    justify-content: space-evenly;
}

.daySelect {
    padding: 1.5rem 0.5rem;
    margin: 0 0.25rem;
    min-width: 3rem;
    min-height: 3rem;
    text-align: center;
    line-height: 0;
    cursor: pointer;
    z-index: 2;
}

.daySelect.active::after,
.week__toggle>div.active::after {
    background-color: var(--accent);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2.25rem;
    border-radius: 5px;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.daySelect>span,
.week__toggle>div>span {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 50% 0;
}

.daySelect.active>span,
.week__toggle>div.active>span {
    position: relative;
    z-index: 2;
}

.content__planning__settings {
    grid-column: 1 / span 2;
}

section.edit__content__planning__container>.container,
section.add__content__planning__container.container {
    margin-top: 80px;
    display: grid;
    grid-template-columns: auto 280px;
    grid-gap: 3rem;
}

section.edit__content__planning__container>.container {
    grid-template-columns: auto 440px;
    grid-gap: 1rem;
    grid-template-rows: min-content;
}

.edit__content__planning__container>.container>h2 {
    grid-column: 1 / span 2;
}

.content__planning__table {
    margin-top: 80px;
}

.content__planning__table ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    cursor: pointer;
}

.edit__content__planning__container .content__planning__form {
    overflow: hidden;
}

.add__content__planning__container .content__planning__form {
    padding-bottom: 4rem;
}

article.content__planning__form h2 {
    font-size: 1.4rem !important;
    margin: 1rem 0 0.5rem;
}

aside.content__info__box {
    background-color: var(--background-accent);
    padding: 1rem;
    border-radius: 8px;
    margin-bottom: auto;
    border: 1px solid var(--background-color);
}

.content__info__box h2 {
    font-size: 1rem !important;
    margin: 1rem 0 0.5rem !important;
}

.store__planning {
    margin-top: 1rem;
}

.content__info__box>div>strong+span {
    margin-left: 1rem;
}

article.content__planning__form>input,
article.content__planning__form .grid.two input,
.rubric__container>input,
.react-datepicker__input-container>input,
input[type="time"] {
    padding: 0.75rem 0.75rem;
    border: none;
    line-height: 1;
    border-radius: 5px;
    width: 100%;
}

.rubric__container>input {
    padding-right: 3rem !important;
}

button.button.remove__rubric {
    position: absolute;
    top: 50%;
    right: 0.5rem;
    height: 2rem;
    width: 2rem;
    transform: translateY(-50%);
}

.content__frequency {
    display: flex;
    flex-direction: column;
}

.content__frequency>label {
    background-color: var(--background-color);
    margin: 0.25rem 0;
    padding: 0.5rem 1rem;
    margin-right: auto;
    border-radius: 15px;
}

.content__frequency>label>input {
    margin-right: 1rem;
    z-index: 1;
    visibility: hidden;
}

.content__frequency>label span::before {
    content: '';
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    left: 0;
    background-color: #fff;
    transform: translate(-200%, -50%);
    border-radius: 50%;
}

.content__frequency>label input:checked+span::before {
    background-color: var(--accent);
}

.rubric__container {
    margin-bottom: 0.5rem;
}

button.button.add__rubric {
    margin-top: 0.5rem;
}

.content__planning__header li {
    font-weight: bold;
    font-size: 1.1rem;
}

.content__planning__actions {
    margin: 0 0 2rem;
}

.content__planning__table div.containt__planning__item {
    padding: 0.75rem 0rem;
    display: grid;
    grid-template-columns: 1fr;
    align-content: center;
}

.content__planning__header {
    background-color: var(--background-accent);
    padding: 0.75rem 0rem;
}

.containt__planning__container {
    border: 1px solid #404040;
    padding: 0rem 0;
    background-color: var(--background-accent);
    border-radius: 8px;
}

.content__planning__table>div+div {
    border-top: 2px solid var(--outline);
}

.content__planning__table .containt__planning__item:nth-child(odd) {
    background-color: var(--background-accent);
}

.content__planning__table ul li {
    text-overflow: ellipsis;
    padding: 0 1rem;
    overflow: hidden;
    white-space: nowrap;
}

.edit__content__planning__container .content__info__box div.containt__planning__item {
    background-color: var(--background-accent);
    padding: 1rem 2rem 0 1rem;
    line-height: 1.5;
}

button.button.add__post {
    margin-top: 1rem;
    position: sticky;
    bottom: 0;
    width: 100%;
}

.edit__content__planning__container .content__info__box div .second {
    margin-top: 0.75rem !important;
}

.drag__scroll {
    cursor: move;
}

.week__toggle {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto auto;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
}

.week__toggle>* {
    padding: 1.5rem 0.5rem;
    margin: 0 0.25rem;
    min-width: 3rem;
    min-height: 3rem;
    text-align: center;
    line-height: 0;
    cursor: pointer;
    z-index: 2;
}

.grid.two.second__week {
    grid-template-columns: auto auto;
}

.calendar__empty__week {
    margin: auto;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-size: 0.8rem;
    position: relative;
    display: block;
    border: 2px solid #3a3a3aa6;
    height: 100%;
    width: 100%;
    text-align: center;
    line-height: 2;
}

span.week__number {
    margin: auto;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    font-size: 0.8rem;
}

section.edit__content__planning__container aside.content__info__box {
    overflow: auto;
    overflow-x: hidden;
}

.context__menu__pop__up {
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 4;
    background-color: #ffffff;
    outline: none;
    padding: 0.5rem 0.75rem;
    border-radius: 5px;
    width: auto !important;
}

.post__item .context__menu__pop__up {
    right: 2rem;
}

.zd__feedback__container>.zd__feedback__item>.grid>.zd__column>.context__menu__pop__up {
    right: 2rem;
}

.default__inner {
    max-width: 175px;
}

.remove__inner,
.remove__inner>p {
    max-width: 400px;
    color: #000000;
}

.context__menu__pop__up>.default__inner>* {
    width: 100%;
    margin: 0.125rem 0;
    padding: 0.5rem;
}

.remove__inner {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
}


.remove__inner p {
    width: 100%;
    margin-bottom: 1rem;
}

.context__menu__pop__up button {
    margin: 0.125rem 1rem 0.125rem 0;
    padding: 0.5rem;
}

.remove__inner .close__menu {
    margin-left: auto;
}

.remove__post {
    background: var(--error) !important;
    color: #fff;
}

.close__menu {
    background: transparent !important;
    margin-top: 5px;
}

button.close__panel {
    background: var(--background-color);
    padding: 0.5rem;
    position: absolute;
    right: 1rem;
    top: 1rem;
}

button.close__panel::before {
    content: '';
    height: 0.75rem;
    width: 0.75rem;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjMgMjAuMTY4bC04LjE4NS04LjE4NyA4LjE4NS04LjE3NC0yLjgzMi0yLjgwNy04LjE4MiA4LjE3OS04LjE3Ni04LjE3OS0yLjgxIDIuODEgOC4xODYgOC4xOTYtOC4xODYgOC4xODQgMi44MSAyLjgxIDguMjAzLTguMTkyIDguMTggOC4xOTJ6Ii8+PC9zdmc+");
    filter: invert(1);
    display: block;
    z-index: 1;
    background-position: center;
    background-size: contain;
}













.zd__upload__form {
    /* padding: 0rem 1rem 1rem; */
}

.zd__inner {
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
}

.zd__inner p {
    line-height: 1.5;
}

.zd__upload__form .zd__inner {
    display: flex;
    flex-wrap: wrap;
}


.zd__inner>h2 {
    order: 1;
}

.zd__inner h2 {
    margin: 0 !important;
}

.zd__upload__form.no__image .zd__inner>h2 {
    font-size: 1.2rem !important;
}

.zd__uploader {
    margin: 0rem 0;
    order: 2;
}

input.zd__text {
    width: 100%;
    border: 1px solid #828282;
    margin: 1rem 0;
    padding: 0.75rem 1rem;
    border-radius: 5px;
    box-shadow: inset 0 0 5px 0 #23232333;
}

button.zd__submit,
button.zd__close,
button.zd__cancel,
button.zd__remove__file {
    background: #f6f6f6;
    border: 1px solid #b6b6b6;
    width: 40%;
    padding: 0.75rem 1rem;
    font-weight: bold;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 #2323230f;
    cursor: pointer;
}

button.zd__close {
    margin-left: 20%;
}


.zd__upload__form .zd__inner>button.zd__close {
    flex: 40%;
    order: 5;
}

.zd__upload__form .zd__inner>* {
    flex: 100% !important;
}

.zd__upload__form .zd__inner>button.zd__submit {
    flex: 40%;
}

.zd__uploader {
    cursor: pointer;
    margin: 1rem 0;
    border: 1px solid #c9c9c9;
    border-radius: 5px;
    min-height: 50px;
    background-size: auto 30% !important;
    background-position: center 20px !important;
    box-shadow: inset 0 0 30px 0 #2323231a;
}

.zd__uploader>input.zd__upload {
    cursor: pointer;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10;
}


.zd__inner button.zd__submit {
    order: 4;
}

.zd__uploader::after {
    content: 'Bestanden uploaden';
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
}

.zd__uploading__items {
    display: flex;
    flex-wrap: wrap;
    order: 3;
    margin-bottom: 1rem;
}

.zd__uploading__items>h2 {
    font-size: 0.8rem !important;
    font-weight: normal;
    border-radius: 5px;
    flex: 100%;
    padding: 0.75rem 0.75rem;
    vertical-align: middle;
    display: flex;
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 3rem !important;
}

.zd__uploading__items>h2>span {
    text-overflow: ellipsis;
    overflow: hidden;
}

.zd__uploading__items>h2>button.zd__cancel,
.zd__feedback__container .google__drive__upload__container .zd__cancel {
    width: 1.2rem;
    height: 1.2rem;
    padding: 0;
    vertical-align: middle;
    margin-left: auto;
    background-color: transparent;
    border-color: #000;
    border-width: 2px;
    filter: invert(1);
    background-size: auto 45%;
}

.zd__uploading__items>h2:nth-child(even) {
    background-color: var(--background-color);
}

button.zd__cancel {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMjQgMjAuMTg4bC04LjMxNS04LjIwOSA4LjItOC4yODItMy42OTctMy42OTctOC4yMTIgOC4zMTgtOC4zMS04LjIwMy0zLjY2NiAzLjY2NiA4LjMyMSA4LjI0LTguMjA2IDguMzEzIDMuNjY2IDMuNjY2IDguMjM3LTguMzE4IDguMjg1IDguMjAzeiIvPjwvc3ZnPg==);
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center;
}




.zd__upload__form.has__image.edit__image .zd__inner,
.zd__upload__form.no__image .zd__inner {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 12;
    background-color: var(--background-color);
    width: 100%;
    height: 100%;
    align-items: center;
    align-content: center;
    text-align: center;
}

.zd__upload__form.has__image.edit__image .zd__inner>*,
.zd__upload__form.no__image .zd__inner>* {
    max-width: 300px;
    margin-left: auto !important;
    margin-right: auto !important;
}

.edit__image .upload__image {
    visibility: hidden;
    display: block;
}

.edit__image .zd__inner>button.zd__cancel,
.zd__feedback__container .google__drive__upload__container .zd__cancel {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    width: 2rem !important;
    border-radius: 50%;
    height: 2rem !important;
    background-size: 40% !important;
    background-color: transparent;
    filter: invert(1);
    border: 2px solid #000;
    padding: 0;
    flex: 2rem 0 0 !important;
}

button.upload__image::after {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNOCAxMGgtNWw5LTEwIDkgMTBoLTV2MTBoLTh2LTEwem0xMSA5djNoLTE0di0zaC0ydjVoMTh2LTVoLTJ6Ii8+PC9zdmc+);
    background-position: center;
    background-size: 50%;
    background-repeat: no-repeat;
    filter: invert(1);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

button.upload__image {
    background: var(--background-color);
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 2rem;
    right: 1rem;
    z-index: 2;
}

.edit__image img {
    z-index: 2;
}

.google__drive__upload__container.is__loading::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: var(--background-color);
    z-index: 10;
}

.google__drive__upload__container.is__loading::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    height: 4rem;
    width: 4rem;
    border: 3px solid #fff;
    z-index: 11;
    border-right: 3px solid transparent;
    animation: 1s ease-in-out infinite loadingRotation;
}

.zd__upload__form.no__image {
    min-height: 230px;
}

.zd__upload__form.no__image .zd__inner>* {
    flex: 100% 1 1 !important;
    width: 100%;
}

@keyframes loadingRotation {
    from {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    to {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}

.zd__feedback__item>.grid {
    position: relative;
    padding: 0.75rem;
    background: radial-gradient(#ffffff1a, transparent 110%);
    border: 1px solid #ffffff1a;
    border-radius: 5px;
    cursor: pointer;
}

.zd__feedback__item.active {
    border-color: #fdcb6f;
}

.zd__feedback__container>.zd__feedback__item {
    margin-bottom: 1rem;
}

.zd__feedback__reactions {
    margin-top: -0rem;
    border-left: 2px solid var(--accent);
    margin-left: 0.75rem;
}

.zd__feedback__item .context__menu {
    transform: scale(0.5) translate(50%, -50%) !important;
    top: 50% !important;
}

.zd__feedback__input input {
    width: 100%;
    padding: 0.25rem 2.75rem 0.25rem 2.75rem;
    line-height: 2;
    outline: 1px solid #ffffff24;
    border: none;
    resize: none;
    border-radius: 5px;
}

.zd__feedback__input {
    border-top: 1px solid #ffffff24;
    position: sticky;
    bottom: -0.25rem;
    z-index: 2;
    margin: 1rem -1rem 0;
    background-color: #1b1b24;
    padding: 1rem;
    margin-top: auto;
    border-radius: 5px;
    border: 1px solid var(--outline);
}

.zd__feedback__input button.zd__send {
    position: absolute;
    top: 50%;
    right: 1.25rem;
    transform: translateY(-50%);
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAxMmwxMSAzLjEgNy04LjEtOC4xNTYgNS42NzItNC4zMTItMS4yMDIgMTUuMzYyLTcuNjgtMy45NzQgMTQuNTctMy43NS0zLjMzOS0yLjE3IDIuOTI1di0uNzY5bC0yLS41NnY3LjM4M2w0LjQ3My02LjAzMSA0LjUyNyA0LjAzMSA2LTIyeiIvPjwvc3ZnPg==);
    filter: invert(1);
    border: none;
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    border-left: 1px solid #000;
    border-radius: 0;
    cursor: pointer;
}

.zd__feedback__item a {
    line-height: 1;
    font-size: 0.8rem;
}

.zd__feedback__item .zd__feedback__item {
    background: var(--background-color);
    padding: 0rem;
}


.zd__planning__container .zd__feedback__item .zd__column h2 {
    position: relative;
    margin: 0 !important;
    font-size: 0.8rem !important;
}

.zd__feedback__item p {
    margin: 0.25rem 0 0 !important;
    font-size: 0.8rem !important;
    line-height: 1;
}

.zd__feedback__item .grid.two {
    grid-template-columns: 25px auto;
    grid-gap: 0.5rem;
}

.zd__feedback__item span.zd__social__logo {
    background-repeat: no-repeat;
    border-radius: 50%;
    height: 25px;
    width: 25px;
}

.zd__attachment {
    position: absolute;
    top: 50%;
    left: 1.25rem;
    transform: translateY(-50%) !important;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTcgNXYxMmMwIDIuNzU3LTIuMjQzIDUtNSA1cy01LTIuMjQzLTUtNXYtMTJjMC0xLjY1NCAxLjM0Ni0zIDMtM3MzIDEuMzQ2IDMgM3Y5YzAgLjU1MS0uNDQ5IDEtMSAxcy0xLS40NDktMS0xdi04aC0ydjhjMCAxLjY1NyAxLjM0MyAzIDMgM3MzLTEuMzQzIDMtM3YtOWMwLTIuNzYxLTIuMjM5LTUtNS01cy01IDIuMjM5LTUgNXYxMmMwIDMuODY2IDMuMTM0IDcgNyA3czctMy4xMzQgNy03di0xMmgtMnoiLz48L3N2Zz4=);
    filter: invert(1);
    border: none;
    background-size: 50%;
    background-position: center;
    background-repeat: no-repeat;
    border-right: 1px solid #000;
    border-radius: 0;
    cursor: pointer;
    z-index: 1;
    margin: 0;
}


.zd__feedback__container .google__drive__upload__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000b5;
    z-index: 5;
}

.zd__upload__form.no__image {
    max-height: 60%;
    max-width: 80%;
    transform: scale(.9);
    margin: auto;
}

.zd__feedback__container .google__drive__upload__container .zd__uploader::after {
    font-size: 0.9rem;
    bottom: 50%;
    transform: translate(-50%, 49%);
}




.finished__post {
    color: #000000;
    font-weight: bold;
    line-height: 1;
    display: block;
    position: relative;
}

.finished__post .slider__container {
    margin-top: 0.5rem;
    width: 100%;
    height: 2rem;
    border-radius: 6px;
    border: 1px solid var(--background-accent);
    background-color: #e3e3e3;
    box-shadow: inset 0 0 13px 0 #00000078;
}

span.finished__slider::after {
    content: '';
    height: 0.6rem;
    position: absolute;
    top: 50%;
    right: 6px;
    border-right: 1px solid #4e4e4e;
    transform: translateY(-50%);
    box-shadow: -3px 0 0 0 #4e4e4e;
}

.finished__post .slider__container input {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
    margin: 0;
    padding: 0;
    cursor: pointer;
}

.finished__post .slider__container input[value="done"] {
    right: 0;
    left: inherit;
}

.finished__post .slider__container .finished__slider {
    position: absolute;
    left: 0.2rem;
    top: 0.2rem;
    background-color: var(--background-accent);
    height: 80%;
    width: 1.5rem;
    border-radius: 5px;
    border: 1px solid var(--background-color);
    transition: 0.2s ease-out;
}

.finished__post .slider__container input[value="done"]:checked~.finished__slider {
    width: calc(100% - 0.4rem);
    background-color: var(--accent);
}

.zd__feedback__container .zd__upload__form {
    position: sticky;
    top: calc(50% - 20%);
}

.zd__uploading__items button.zd__cancel {
    top: 0 !important;
    right: 0 !important;
}

.zd__upload__form>.zd__inner>button.zd__cancel {
    top: 0 !important;
    right: 0 !important;
    transform: translate(0%, -150%);
}

span.zd__label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    line-height: 0;
    opacity: 0.6;
    font-weight: normal;
    color: #000;
    font-size: 0.8rem !important;
    margin-top: -0.05rem;
}

.finished__post {
    padding: 0 !important;
    margin-bottom: 1rem !important;
}

.zd__show__done {
    display: flex;
    justify-content: start;
    margin: 0.5rem 0 1rem;
    line-height: 1;
    font-size: 0.8rem;
    cursor: pointer;
    position: sticky;
    top: 44px;
    z-index: 10;
    background-color: var(--background-color);
    margin: 0rem -1rem 0;
    padding: 1rem;
    font-weight: bold;
}

.zd__show__done {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox__container {
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
}

.zd__show__done .checkbox__container input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    cursor: pointer;
}

span.checkbox {
    width: 1rem;
    height: 1rem;
    display: block;
    border: 1px solid var(--accent);
    border-radius: 2px;
}

input:checked+span.checkbox {
    background-color: var(--accent);
}

input:checked+span.checkbox::before {
    content: '';
    width: 0.25rem;
    height: 0.5rem;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -63%) rotate(25deg);
}

.edit__content__planning__container article.content__planning__form {
    position: absolute;
    width: 440px;
    height: auto;
    z-index: 10;
}

h2.calendar__month {
    margin: 0rem auto 0.5rem !important;
}

.post__item {
    display: flex;
    flex-wrap: wrap;
}

.zd__social {
    flex: 525px 0 0;
}

.social__item__container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.zd__social+.zd__feedback__panel {
    position: absolute;
    overflow: auto;
    left: calc(525px + 2rem);
    right: 1rem;
    top: 1rem;
    bottom: 1.5rem;
    height: auto;
    max-height: 70vh;
    display: flex;
    flex-direction: column;
    background-color: var(--background-accent);
    padding: 0rem 1rem 0;
    border-radius: 5px;
    border: 1px solid var(--background-color);
}

.zd__show__done+.zd__feedback__item {
    margin-top: 1rem;
}

.content__info__box>.calendar__container {
    margin-top: 1rem;
}

.post__inner__container {
    display: flex;
    flex-wrap: wrap;
}

.post__mode__switcher {
    display: flex;
    margin: 0 -1rem;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #22222a;
}

.post__mode__switcher>* {
    width: 50%;
}

.post__mode__switcher>* {
    width: 50%;
}

.zd__feedback__container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.post__mode__switcher button {
    background: transparent;
    color: #fff;
}

.post__mode__switcher button.active {
    background: linear-gradient(0deg, var(--accent) 60%, var(--secondary));
}

.content__info__box .grid+.grid {
    margin-top: 1rem;
}

span.tag {
    display: inline-block;
    background-color: var(--background-color);
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    border-radius: 8px;
    margin-bottom: 0.5rem;
}

aside.content__info__box.calendar {
    position: sticky;
    top: 1rem;
}

section.edit__content__planning__container {
    width: 100%;
}

button.rubric__edit__button {
    background: transparent;
    color: var(--accent);
    padding: 0 !important;
    margin-left: 1rem;
    text-decoration: underline;
}

input[name="add-rubric"] {
    width: 100%;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    margin-top: 0.5rem;
}

button.add__rubric {
    position: absolute;
    right: 0;
    bottom: 0;
    height: 2.4rem;
    width: 2.4rem;
}

button.add__rubric::before {
    content: '';
    border: 8px solid transparent;
    height: 0;
    width: 0;
    display: block;
    border-left-color: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-10%, -50%);
}

.google__drive__upload__container.is__loading img {
    opacity: 0;
    transition: unset;
}

.google__drive__upload__container {
    min-height: 240px;
}

.google__drive__upload__container img {
    transition: 0.1s ease-in 0.1s;
}












.list__table__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    grid-column: 1 / span 2;
    background-color: var(--background-color);
    padding: 2rem 400px 2rem 2rem;
    border-radius: 5px;
    min-height: 400px;
}

.list__needs {
    position: absolute;
    right: 2rem;
    top: 1rem;
    bottom: 0;
    width: 336px;
}

.list__table__item>* {
    flex: 10% 1 1;
    display: block;
}

.list__table__item>*+* {
    margin-top: 1rem;
}

h2.list__name {
    font-size: 1rem !important;
}

.list__input .post__mode__switcher {
    display: none;
}

.list__input .zd__feedback__container {
    padding: 0 1rem;
}

.list__description textarea {
    margin: 0;
}

.list__table__item select {
    margin-bottom: auto;
    border-color: #4a4a4fa6;
    border-radius: 5px;
    padding: 0.66rem 0.75rem;
    font-size: 1rem !important;
    width: 100%;
}

.list__name input {
    padding: 0.75rem 0.75rem;
    border: none;
    line-height: 1;
    border-radius: 5px;
    width: 100%;
}

.list__needs textarea {
    margin: 0;
}

.list__needs {
    display: grid;
    grid-template-rows: 1fr;
    height: 100%;
    margin: 0;
    padding: 1rem 0 3rem;
}

.zd__feedback__panel {
    height: 100%;
}

.list__input .zd__feedback__item h2 {
    font-size: 1rem !important;
}

.list_needs .list__input {
    overflow: auto;
    height: 100%;
}

.list__needs .list__input {
    height: 100%;
    overflow: hidden;
}

.zd__feedback__container {
    overflow: auto;
}

.list__input .zd__feedback__container .zd__show__done {
    top: 0;
}

.list__input .zd__feedback__container .zd__feedback__input {
    bottom: 0;
}

h2.date__label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-15%, -40%);
    background-color: var(--accent);
    padding: 0.5rem 0.5rem;
    font-size: 1.2rem !important;
}

.list__table__item {
    margin-top: 1rem;
}

.list__table__item>div>strong {
    margin-bottom: 0.5rem;
    display: block;
}

.list__table__item textarea {
    margin: 0 !important;
    /* max-height: 4rem; */
}

.add__container {
    position: relative;
    padding: 1rem;
    background-color: #191a22;
    z-index: 100;
    border-radius: 5px;
    margin-top: 1rem;
}

.add__container>div {
    display: flex;
}

.add__container button {
    margin-left: 1rem;
}

.add__container>strong {
    margin-bottom: 0.5rem;
    display: block;
}


.preview .zd__feedback__item h2 {
    font-size: 1rem !important;
}

.post__item.preview {
    grid-column: 1 / span 2;
}

.post__item.preview .zd__feedback__panel {
    position: relative;
    left: inherit;
    top: inherit;
    bottom: inherit;
    right: inherit;
    padding: 1rem;
}

.post__item.preview .post__inner__container {
    width: 100%;
}

.post__item.preview .post__inner__container {
    display: grid;
    grid-template-columns: 600px 1fr;
    grid-gap: 1rem;
}

.post__item.preview .post__mode__switcher {
    display: none;
}

.preview .zd__show__done,
.preview .zd__feedback__input {
    margin: 0;
}

.preview .zd__feedback__input {
    margin-top: auto;
    bottom: 0;
}

.preview .zd__show__done {
    top: 0;
}

.preview .zd__feedback__container {
    min-height: 320px;
}

.preview .zd__social {
    margin-bottom: auto;
    margin-top: 0;
}

.preview .zd__social__description {
    line-height: var(--line-height);
    word-break: break-word;
}

.zd__social.has__passed {
    opacity: 0.5;
}

.empty__feedback {
    display: flex;
    flex-direction: column;
    background-color: var(--background-accent);
    padding: 0rem 1rem 0;
    border-radius: 5px;
    border: 1px solid var(--background-color);
    opacity: 0.5;
}

.preview .zd__social__description>p {
    max-height: 8rem;
    overflow: hidden;
    white-space: break-spaces;
}

.preview .zd__social__description.expand>p {
    max-height: none;
}

.preview .zd__social__description p>span {
    display: block;
    font-size: 0.9rem;
    white-space: pre-line;
    line-height: 2;
    margin: 1rem 0;
    overflow-y: hidden;
}

.toggle__hide__container {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #232323;
    grid-column: 1 / span 2;
    padding: 1rem var(--padding);
    margin-left: calc(-8px - var(--padding));
    margin-right: calc(-8px - var(--padding));
    z-index: 10;
}

.toggle__hide__container.finished__post {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5rem !important;
    margin-bottom: 0 !important;
}

.toggle__hide__container .slider__container {
    max-width: 18rem;
    margin: 0;
}

.toggle__hide__container span.zd__label {
    margin: 0.025rem 0 0;
    width: 100%;
    text-align: center;
}

.preview .zd__feedback__item.active>.grid.two {
    border: 1px solid #fdcb6f;
}

.zd__feedback__item.done .context__menu {
    background-color: var(--success);
}

.user__column h2 {
    margin-bottom: 2rem;
}

.app__inner.grid.one.user__overview {
    margin-top: 4rem;
}

.user__inner__column>.grid+.grid {
    margin-top: 1rem;
}

.user__column .grid>a.button {
    margin-left: auto;
}

.user__inner__column {
    background-color: var(--background-accent);
    padding: 2rem;
    border-radius: 5px;
    border: 2px solid var(--background-color);
    margin-right: auto;
}

.user__inner__column>.grid>strong {
    margin: auto auto auto 0;
}

.zd__social__details.grid.two {
    grid-template-columns: max-content auto;
    grid-gap: 0.5rem;
    margin-bottom: 1rem;
    align-items: center;
}

strong.input__label {
    outline: none !important;
    padding-left: 0;
    padding-bottom: 0;
}

h2.needs__title {
    font-size: 1.3rem !important;
}

.grid.six {
    grid-template-columns: repeat(6, 1fr);
}

.grid.five {
    grid-template-columns: repeat(5, 1fr);
}

.post__item.preview>.grid.five {
    margin-bottom: 0rem;
}

.deadline__table {
    background-color: var(--background-color);
    border: 1px solid var(--outline);
    border-radius: 5px;
    margin-top: 1rem !important;
}

.exporter__container,
.deadline__container {
    margin-top: 1rem;
}

.deadline__table>* {
    border-top: 1px solid var(--outline);
    padding: 1rem 1rem;
    margin-top: 0 !important;
    grid-gap: 0.5rem;
}

.react-pdf__Page {
    display: inline-block;
    width: 525px;
    height: auto !important;
    scroll-snap-align: start;
}

.react-pdf__Document {
    overflow: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    width: 525px;
    scroll-snap-type: x mandatory;
}

canvas.react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
}

.zd__image__overlay video {
    max-width: 100%;
}


.social__switcher {
    width: 100%;
    display: flex;
}

button.social__switch {
    flex: auto;
}

button.social__switch.disabled {
    background: #232323;
    opacity: 0.5;
    cursor: not-allowed;
}

button.social__switch {
    background: #5f5f5f !important;
}

button.social__switch.active {
    background: linear-gradient(0deg, var(--accent) 60%, var(--secondary)) !important;
}

.social__followers {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 80%;
    padding-left: 1rem;
}

.setting__option .setting__option input[type="number"] {
    width: 140px;
}

.setting__option .setting__option label {
    width: 140px;
    font-size: 0.9rem;
}

.social__channels {
    margin-top: 2rem;
}

select[name="instagram_post_type"] {
    width: 525px;
    margin: 1rem 0 0;
    border-radius: 5px;
}


.instagram__story .zd__social__image,
.instagram__reel .zd__social__image {
    aspect-ratio: 9/16;
    max-height: 60vh;
    margin: auto;
    overflow: hidden;
}

.instagram__story .zd__social__image img,
.instagram__reel .zd__social__image img {
    object-fit: contain;
    height: 100%;
    width: 100%;
    margin-top: 0;
}

.instagram__story .zd__social__image::before,
.instagram__reel .zd__social__image::before {
    content: '';
    background: var(--background-gradient);
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    height: 100%;
    width: 100%;
}

.instagram__story .zd__social__image::after {
    box-shadow: 0 0 0 1px #ffffff,
        35px 0 0 1px #ffffff,
        70px 0 0 1px rgb(255 255 255 / 59%),
        105px 0 0 1px rgb(255 255 255 / 59%),
        140px 0 0 1px rgb(255 255 255 / 59%),
        175px 0 0 1px rgb(255 255 255 / 59%),
        210px 0 0 1px rgb(255 255 255 / 59%),
        245px 0 0 1px rgb(255 255 255 / 59%);
    width: 30px;
    position: absolute;
    content: '';
    left: 0px;
    top: 10px;
    transform: scale(0.9) translateX(45%);
}

.instagram__story .google__drive__upload__container,
.instagram__story .zd__upload__form,
.instagram__story .zd__image__overlay,
.instagram__reel .google__drive__upload__container,
.instagram__reel .zd__upload__form,
.instagram__reel .zd__image__overlay {
    height: 100%;
}

.instagram__story .zd__social__head.grid.two {
    position: absolute;
    top: 1.5rem;
    left: 1rem;
    line-height: 1;
    align-items: center;
    grid-template-columns: 20px auto;
}

.instagram__story .zd__social__head.grid.two span.zd__social__logo {
    height: 20px;
    width: 20px;
}

.instagram__story button.upload__image,
.instagram__reel button.upload__image {
    top: 1.25rem;
    right: -0.75rem;
}

.zd__social.instagram.instagram__story span.instagram__story__date {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    text-align: center;
    opacity: 0.2;
    transition: opacity 0.2s ease-in-out;
    line-height: 1;
    transform: translateX(-50%);
    width: max-content;
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
}

.zd__social.instagram.instagram__story .zd__social__image:hover span.instagram__story__date {
    opacity: 1;
}

span.instagram__story__date {
    background-color: var(--background-color);
    display: block;
}




.zd__social.instagram span.zd__social__like::before {
    background: url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtNy4yMzQgMy4wMDRjLTIuNjUyIDAtNS4yMzQgMS44MjktNS4yMzQgNS4xNzcgMCAzLjcyNSA0LjM0NSA3LjcyNyA5LjMwMyAxMi41NC4xOTQuMTg5LjQ0Ni4yODMuNjk3LjI4M3MuNTAzLS4wOTQuNjk3LS4yODNjNC45NzctNC44MzEgOS4zMDMtOC44MTQgOS4zMDMtMTIuNTQgMC0zLjM1My0yLjU4LTUuMTY4LTUuMjI5LTUuMTY4LTEuODM2IDAtMy42NDYuODY2LTQuNzcxIDIuNTU0LTEuMTMtMS42OTYtMi45MzUtMi41NjMtNC43NjYtMi41NjN6bTAgMS41YzEuOTkuMDAxIDMuMjAyIDEuMzUzIDQuMTU1IDIuNy4xNC4xOTguMzY4LjMxNi42MTEuMzE3LjI0MyAwIC40NzEtLjExNy42MTItLjMxNC45NTUtMS4zMzkgMi4xOS0yLjY5NCA0LjE1OS0yLjY5NCAxLjc5NiAwIDMuNzI5IDEuMTQ4IDMuNzI5IDMuNjY4IDAgMi42NzEtMi44ODEgNS42NzMtOC41IDExLjEyNy01LjQ1NC01LjI4NS04LjUtOC4zODktOC41LTExLjEyNyAwLTEuMTI1LjM4OS0yLjA2OSAxLjEyNC0yLjcyNy42NzMtLjYwNCAxLjYyNS0uOTUgMi42MS0uOTV6IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=);
    height: 1.4rem;
    width: 1.4rem;
}

.zd__social.instagram span.zd__social__comment::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgM2M1LjUxNCAwIDEwIDMuNjg1IDEwIDguMjEzIDAgNS4wNC01LjE0NiA4LjE1OS05LjkxMyA4LjE1OS0yLjAyNyAwLTMuNTQ4LS40MzktNC41NDgtLjcxMmwtNC4wMDQgMS4xOTYgMS4yNTItMi45Yy0uOTUyLTEtMi43ODctMi41ODgtMi43ODctNS43NDMgMC00LjUyOCA0LjQ4Ni04LjIxMyAxMC04LjIxM3ptMC0yYy02LjYyOCAwLTEyIDQuNTczLTEyIDEwLjIxMyAwIDIuMzkuOTMyIDQuNTkxIDIuNDI3IDYuMTY0bC0yLjQyNyA1LjYyMyA3LjU2My0yLjI2YzEuNTg1LjQzNCAzLjEwMS42MzIgNC41MjMuNjMyIDcuMDk4LjAwMSAxMS45MTQtNC45MzEgMTEuOTE0LTEwLjE1OSAwLTUuNjQtNS4zNzItMTAuMjEzLTEyLTEwLjIxM3oiLz48L3N2Zz4=);
    height: 1.2rem;
    width: 1.2rem;
    background-size: contain;
}

.zd__social.instagram span.zd__social__send::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCAxMmwxMSAzLjEgNy04LjEtOC4xNTYgNS42NzItNC4zMTItMS4yMDIgMTUuMzYyLTcuNjgtMy45NzQgMTQuNTctMy43NS0zLjMzOS0yLjE3IDIuOTI1di0uNzY5bC0yLS41NnY3LjM4M2w0LjQ3My02LjAzMSA0LjUyNyA0LjAzMSA2LTIyeiIvPjwvc3ZnPg==);
    height: 1.2rem;
    width: 1.2rem;
    background-size: contain;
    transform: rotateY(0deg) translateY(-50%);
}

span.zd__social__bookmark {
    line-height: 1;
    padding: 7px 0.75rem 7px 2rem;
    font-size: 0.9rem;
    border-radius: 5px;
    margin-left: 1rem;
}

span.zd__social__bookmark::before {
    content: '';
    height: 1.2rem;
    width: 1.2rem;
    display: block;
    filter: invert(1);
    background-size: contain;
    transform: rotateY(180deg) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 0.5rem;
    margin-right: 2rem;
}

.zd__social span.zd__social__bookmark::before {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYgMnYxNy41ODJsLTQtMy41MTItNCAzLjUxMnYtMTcuNTgyaDh6bTItMmgtMTJ2MjRsNi01LjI2OSA2IDUuMjY5di0yNHoiLz48L3N2Zz4=);
    height: 1.4rem;
    width: 1.4rem;
    background-size: 100% 120%;
}

.zd__social.instagram span.zd__social__bookmark {
    margin-left: auto;
}

.zd__social.instagram .zd__social__actions>span:hover {
    background-color: transparent;
}


.instagram.instagram__reel .zd__social__head {
    line-height: 1.7;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    right: 1rem;
}

.instagram.instagram__reel .zd__social__description {
    grid-column: 1 / span 2;
}

.instagram.instagram__reel .zd__social__actions {
    flex-direction: column;
    position: absolute;
    bottom: 1rem;
    right: 0;
}

.instagram.instagram__reel .zd__social__actions>span {
    margin: 2rem 0 0;
    padding: 0 0.75rem;
}

.instagram.instagram__reel .zd__edit__description {
    margin-bottom: -0.7em;
    margin-top: 0;
}


.instagram.instagram__reel span.show__more {
    text-indent: -9999999px;
    text-align: left;
}

.instagram.instagram__reel span.show__more::after {
    content: '... meer';
    display: block;
    position: absolute;
    bottom: -1.3rem;
    text-indent: 0;
    left: 0rem;
}

.instagram.instagram__reel span.zd__social__logo {
    height: 20px;
}

.instagram.instagram__reel .zd__social__head {
    grid-template-columns: 20px auto;
}

.instagram.instagram__reel .zd__social__description {
    width: 90%;
}

.instagram.instagram__reel .zd__social__description>span:first-child {
    line-height: 1;
    margin-bottom: 0.67em;
    display: block;
    font-size: 80%;
    opacity: 0.6;
}

.instagram.instagram__post span.zd__social__like {
    margin-left: 0;
}

.instagram.instagram__post .zd__social__description {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.zd__social__description>p {
    margin-top: 1rem;
}

.zd__social__image:not(.has__image) {
    padding: 0rem 1rem 1rem;
}





.article__view {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.article__view>strong {
    margin: 1rem 0;
}

.article__view>input {
    width: 100%;
    padding: 0.5rem 0.75rem;
    margin-bottom: 1rem;
    line-height: 2;
    outline: 1px solid #ffffff24;
    border: none;
    resize: none;
    border-radius: 5px;
}

.article__view>iframe {
    aspect-ratio: 16/9;
    margin-top: 0.5rem;
}


.article__view.fullscreen {
    position: fixed;
    top: 0rem;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000000;
    padding: 4rem var(--padding) 2rem;
    background: #000000;
}



.article__view.fullscreen>iframe {
    aspect-ratio: unset;
    height: 100%;
    width: 100%;
}


button.expand__file::before {
    content: '';
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTYgMHYyaC04di0yaDh6bTIgMmg0djRoMnYtNmgtNnYyem0tMTYgNHYtNGg0di0yaC02djZoMnptMjIgMmgtMnY4aDJ2LTh6bS0yIDEwdjRoLTR2Mmg2di02aC0yem0tMjIgNmgxNnYtMTZoLTE2djE2eiIvPjwvc3ZnPg==);
    filter: invert(1);
    background-color: transparent;
    border: none;
    width: 2rem;
    height: 2rem;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

button.expand__file {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    z-index: 100000;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 5px;
}

button.reduce__file {
    position: absolute;
    bottom: var(--padding);
    left: 2rem;
    z-index: 100000;
    width: 2.5rem;
    margin-left: 3.5rem;
    height: 2.5rem;
    border-radius: 5px;
}

button.reduce__file::before {
    content: '';
    background: url(data:image/svg+xml;base64,PHN2ZyBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLW1pdGVybGltaXQ9IjIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJtMjIgNWMwLS40NzgtLjM3OS0xLTEtMWgtMThjLS42MiAwLTEgLjUxOS0xIDF2MTRjMCAuNjIxLjUyIDEgMSAxaDE4Yy40NzggMCAxLS4zNzkgMS0xem0tNiAxMy41di03LjVjMC0uNDc4LS4zNzktMS0xLTFoLTExLjV2LTQuNWgxN3YxM3oiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==);
    width: 2rem;
    height: 2rem;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    filter: invert(1);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.zd__social__tagged {
    margin: 1.5rem 0 0.5rem;
    line-height: 0;
}

.minimal .post__inner__container {
    display: flex !important;
    justify-content: center;
    width: 100% !important;
    margin: 0 0 2rem 0 !important;
    max-width: 525px;
}

aside.content__info__box select {
    max-width: 100%;
}