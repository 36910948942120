h2.onboarding__title {
    font-size: 1.2rem !important;
    max-width: 750px;
    width: 80vw;
    text-align: left;
    line-height: 1.6 !important;
    font-family: var(--heading);
    margin: 1rem auto 1.5rem;
}

h2.onboarding__title.center {
    text-align: center;
}

h3.onboarding__category {
    font-size: 3rem !important;
    font-family: var(--heading);
    margin: 0rem 0 1rem !important;
    order: -1;
    text-align: center;
    color: var(--accent);
}

h4.onboarding__sub__category {
    font-size: 2rem !important;
    font-family: var(--heading);
    margin: -1rem 0 1rem !important;
    order: -1;
    text-align: center;
}

.onboarding__slide {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    justify-content: center;
}

.slide__container {
    margin-top: -70px;
    width: 100%;
}

.onboarding__question {
    visibility: hidden;
    display: none;
    opacity: 0;
}

.onboarding__question.active {
    display: flex;
    visibility: visible;
    flex-direction: column;
    animation: questionAppear 0.3s ease-in-out;
    opacity: 1;
    transition: 0.3s ease-in-out;
    margin-top: 20vh;
    margin-bottom: 3rem;
}

.onboarding__title {
    display: block;
    font-weight: bold;
    max-width: 70%;
    margin: 0 auto 2rem;
    text-align: center;
}

.onboarding__question input,
.onboarding__question textarea,
.onboarding__question select {
    transition: 0.2s ease-in;
    max-width: 80vw;
    width: 750px;
    margin: 0rem auto;
    display: block;
    border-radius: 5px;
    background: var(--background-gradient);
    outline: 1px solid #2d2d2d;
    border: 2px solid transparent;
    padding: 1rem 1rem;
    opacity: 0.7;
}

.onboarding__question textarea {
    resize: none;
    min-height: 200px;
}

.onboarding__question input:focus,
.onboarding__question textarea:focus,
.onboarding__question select:focus {
    border-bottom: 2px solid var(--accent);
    opacity: 1;
}

.slider__actions {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: 2rem
}


@keyframes questionAppear {
    0% {
        opacity: 0;
        transform: translateY(10%) scale(0.95);
    }

    100% {
        opacity: 1;
        transform: translateY(0%) scale(1);
    }
}


label.radio__option>span {
    display: inline-block;
    position: relative;
    margin: 1rem;
    background-color: var(--background-color);
    padding: 1rem 2rem;
    border-radius: 5px;
    outline: 1px solid var(--outline);
    transition: 0.2s ease-out;
    cursor: pointer;
}

input.onboarding_question[type="radio"],
input.radio__item[type="radio"] {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
}

.radio__container>h2 {
    width: 100%;
    flex: 100%;
    text-align: center;
    font-size: 1.2rem !important;
}


.radio__container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

label.radio__option> :checked+span {
    background-color: var(--accent);
}

button.store {
    background: linear-gradient(45deg, #4e1fd1, #2d1278);
    color: #ffffff;
}

button.store::before {
    content: '';
    width: 1.2rem;
    height: 1.2rem;
    display: inline-block;
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTQgM2gyLjk5N3Y1aC0yLjk5N3YtNXptOSAxdjIwaC0yMnYtMjRoMTcuOTk3bDQuMDAzIDR6bS0xNyA1aDEydi03aC0xMnY3em0xNCA0aC0xNnY5aDE2di05eiIvPjwvc3ZnPg==);
    background-size: contain;
    margin-right: 0.5rem;
    top: 0.2rem;
    position: relative;
    filter: invert(0.85);
}

button.previous__slide,
button.next__slide {
    background: linear-gradient(180deg, #f6bb31, #dc9d09);
}

::selection {
    color: #000;
    background: #4e1fd1;
    ;
}

::-moz-selection {
    color: #000;
    background: #4e1fd1;
    ;
}