.boolean__search__container{
    margin: auto;
    width: 100%;
    max-width: 800px;
    padding: 2rem;
    box-shadow: 0px 0px 30px 0px var(--shadow-color);
    border-radius: 8px;
}


.boolean__search__item {
    padding: 0 1.5rem;
}

.boolean__search__container > .return__link {margin-top: 0;margin-bottom: 2rem;position: absolute;margin-left: -2rem;bottom: 100%;}

.boolean__search__container > h2 {top: -0.6rem;margin-bottom: 1rem;display: flex;}

.boolean__search__inner {    
    overflow: hidden auto;
    max-height: 60vh;
    margin-bottom: 2rem;
    box-shadow: inset 0px 0px 20px var(--background-color);
    border-radius: 8px;
}

.boolean__search__item + .boolean__search__item {
    border-top: 2px solid var(--outline);
}

.boolean__search__preview textarea {
    resize: none;
    width: 100% !important;
    height: 80%;
    margin: 0;
}

.boolean__search__item:nth-child(even) {
    background-color: var(--background-accent);
}

.boolean__search__item > h2 {
    font-size: 1rem !important;
    padding: 1rem 0;
    cursor: pointer;
}

.boolean__search__preview {
    max-height: 0;
    overflow: hidden; 
    transition: 0.5s ease;
    padding: 0 1rem;
    background-color: var(--background-accent);
    white-space: break-spaces;
}

.boolean__search__inner > .i__add {margin-top: 1rem;}

.active > .boolean__search__preview {
    max-height: 350px;
    padding: 1rem 4rem 1rem 1rem;
}

.active.boolean__search__item {  padding: 0 1.5rem 1.5rem; }

.active.boolean__search__item > h2::before {transform: translateY(-50%) rotate(45deg);}

.boolean__search__item > h2::before {content: '';position: absolute;top: 50%;right: 0;transform: translateY(-50%) rotate(-135deg);width: 0.5rem;height: 0.5rem;border-left: 2px solid;border-top: 2px solid;}

.boolean__search__preview > button { transition: 0.2s ease-out; position: absolute;right: 1rem;bottom: 50%; transform: translateY(50%);background-color: transparent;filter: invert(1);border: none;}
.active .boolean__search__preview > button { bottom: 1rem; transform: translateY(0%); }


.boolean__search__builder__preview {white-space: break-spaces;
    box-shadow: inset 0 0 16px 0 var(--background-color);
    padding: 1rem;
    border-radius: 8px;}

    .boolean__search__builder {display: flex;flex-direction: row;flex-wrap: wrap;justify-content: space-between;background-color: var(--background-accent);padding: 2rem;border-radius: 8px;}

.boolean__search__builder .input__container {flex: 100%;}

.boolean__search__builder > * {flex: 48% 0 0;}

.app__inner.social__selling {padding: 0; display: flex;flex-direction: row;justify-content: space-between;align-items: center;}

.app__inner.social__selling > * {flex: 48% 0 0;}


.boolean__search__container .pop__up__inner {
    max-width: 1200px;
    width: 100%;
}

.boolean__search__container .pop__up__inner h3 {
    font-size: 1.2rem !important;
    margin-bottom: 1rem;
}

.boolean__search__container .pop__up__inner textarea {
    resize: none;
    height: 300px;
}

.boolean__search__container .pop__up__inner::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2c2d35;
    filter: blur(-10px);
    z-index: -1;
}

.boolean__search__container .post__pop__up__container {
    position: fixed;
    top: 50%;
    left: 50%;
    bottom: inherit;
    right: inherit;
    width: 100%;
    max-width: 1000px;
    transform: translate(-50%, -50%);
}

.boolean__search__output {
    grid-template-columns: 1fr auto !important;
    margin-bottom: 2rem;
}

.boolean__search__output p {
    padding: 1rem 1.5rem;
    background-color: var(--background-color);
    border-radius: 8px;
    outline: 1px solid var(--outline);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.coppied button {
    background: #91cc49 !important;
}

.error button {
    background: #cc4949 !important;
}

.boolean__search__preview > div::after {
    content: ' ';
    position: absolute;
    right: 0;
    opacity: 0;
    top:0.25rem;
    transform: translateX(50%);
    padding:0.25rem 0.5rem;
    transition: 0.2s ease-out;
    border-radius:8px;
    line-height:1;
}

.boolean__search__preview > div.coppied::after {
    content: 'Gekopieerd';
    background-color: var(--success);
}

.boolean__search__preview > div.error::after {
    content: 'Kon niet kopieren';
    background-color: var(--success);
}

.boolean__search__preview > div.coppied::after, .boolean__search__preview > div.error::after {
    opacity: 1;
    transform:translateX(0);
}

.boolean__search__preview > div > p {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/* .boolean__search__builder .input__container .input__field {margin-bottom: 0;} */