:root {
	--padding: 5%;
	--max-container-size: 1400px;

	--text-color: #000000;
	--background-color: #f4f4f4;

	--block-margin: 7rem;
	--gap: 2rem;

	--font-size: 16px;
	--line-height: 1.7;

	--h1: 3rem;
	--h2: 2.6rem;
	--h3: 2.2rem;
	--h4: 1.8rem;
	--h5: 1.4rem;
	--h6: 1rem;
	--heading-line-height: 1.4;

	--font: "sans-serif";
	--font-alt: "sans-serif";

	--text-margin: 1rem;
}

* {
	position: relative;
	box-sizing: border-box;
}

body {
	font-size: var(--font-size) !important;
	line-height: var(--line-height) !important;
	font-family: var(--font) !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: var(--font-alt) !important;
	line-height: var(--heading-line-height) !important;
	font-weight: bold !important;
}

h1 {
	font-size: var(--h1) !important;
}

h2 {
	font-size: var(--h2) !important;
}

h3 {
	font-size: var(--h3) !important;
}

h4 {
	font-size: var(--h4) !important;
}

h5 {
	font-size: var(--h5) !important;
}

h6 {
	font-size: var(--h6) !important;
}

h1+h1,
h1+h2,
h1+h3,
h1+h4,
h1+h5,
h1+h6,
h1+p,
h1+ul,
h1+ol,
h1+blockquote,
h1+.button,
h2+h1,
h2+h2,
h2+h3,
h2+h4,
h2+h5,
h2+h6,
h2+p,
h2+ul,
h2+ol,
h2+blockquote,
h2+.button,
h3+h1,
h3+h2,
h3+h3,
h3+h4,
h3+h5,
h3+h6,
h3+p,
h3+ul,
h3+ol,
h3+blockquote,
h3+.button,
h4+h1,
h4+h2,
h4+h3,
h4+h4,
h4+h5,
h4+h6,
h4+p,
h4+ul,
h4+ol,
h4+blockquote,
h4+.button,
h5+h1,
h5+h2,
h5+h3,
h5+h4,
h5+h5,
h5+h6,
h5+p,
h5+ul,
h5+ol,
h5+blockquote,
h5+.button,
h6+h1,
h6+h2,
h6+h3,
h6+h4,
h6+h5,
h6+h6,
h6+p,
h6+ul,
h6+ol,
h6+blockquote,
h6+.button,
p+h1,
p+h2,
p+h3,
p+h4,
p+h5,
p+h6,
p+p,
p+ul,
p+ol,
p+blockquote,
p+.button,
ul+h1,
ul+h2,
ul+h3,
ul+h4,
ul+h5,
ul+h6,
ul+p,
ul+ul,
ul+ol,
ul+blockquote,
ul+.button,
ol+h1,
ol+h2,
ol+h3,
ol+h4,
ol+h5,
ol+h6,
ol+p,
ol+ul,
ol+ol,
ol+blockquote,
ol+.button,
blockquote+h1,
blockquote+h2,
blockquote+h3,
blockquote+h4,
blockquote+h5,
blockquote+h6,
blockquote+p,
blockquote+ul,
blockquote+ol,
blockquote+blockquote,
blockquote+.button,
.button+h1,
.button+h2,
.button+h3,
.button+h4,
.button+h5,
.button+h6,
.button+p,
.button+ul,
.button+ol,
.button+blockquote,
.button+.button {
	margin-top: var(--text-margin) !important;
}

strong {
	font-weight: bold;
}

em {
	font-style: italic;
}

/* BUTTON */

.button,
.button:visited {
	display: inline-block;
	padding: 0.25rem 0.75rem;
	background-color: #efefef;
	border-radius: 5px;
	text-decoration: none;
	color: var(--color-text);
	outline: none;
}

.ghost {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 10;
}

.button:hover,
.button:active {
	color: var(--color-text);
	text-decoration: none;
	outline: none;
}

/* END BUTTON */

/* TEXT */

.text__center {
	text-align: center;
}

.text__left {
	text-align: left;
}

.text__right {
	text-align: right;
}

/* END TEXT */

/* CONTAINER */

.block__container {
	margin-top: var(--block-margin);
	margin-bottom: var(--block-margin);
}

.block__container.no__margin {
	margin-top: inherit;
	margin-bottom: inherit;
}

.block__container.padding {
	padding-top: var(--block-margin);
	padding-bottom: var(--block-margin);
}

.container {
	padding-left: var(--padding);
	padding-right: var(--padding);
	margin-left: auto;
	margin-right: auto;
	max-width: var(--max-container-size);
	width: 100%;
}

.block__container>.container.text__center {
	max-width: 800px;
	margin: var(--block-margin) auto;
}

.block__container.padding>.container.text__center {
	margin: 0 auto;
}

/* END CONTAINER */

/* IMAGE */

figure.block__image .gd__placeholder__image {
	object-fit: cover;
	width: 100%;
	height: 250px;
}

/* END IMAGE */

/* GRID */

.grid {
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto;
	grid-gap: var(--gap);
}

.grid.one {
	grid-template-columns: repeat(1, 1fr);
}

.grid.two {
	grid-template-columns: repeat(2, 1fr);
}

.grid.three {
	grid-template-columns: repeat(3, 1fr);
}

.grid.four {
	grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 991px) and (min-width: 768px) {

	.grid.three,
	.grid.four {
		grid-template-columns: repeat(2, 1fr);
	}

	.grid.three .column,
	.grid.four .column {
		order: 1;
	}

	.grid.three .column+.column,
	.grid.four .column+.column {
		order: 3;
	}

	.grid.three .column+.column+.column,
	.grid.four .column+.column+.column {
		order: 2;
	}

	.grid.four .column+.column+.column+.column {
		order: 4;
	}
}

@media screen and (max-width: 768px) {

	.grid.two,
	.grid.three,
	.grid.four {
		grid-template-columns: repeat(1, 1fr);
	}

	.grid>.column {
		order: inherit;
	}
}

/* END GRID */

/* FLEX */

.flex {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: calc(0px - calc(var(--gap) / 2));
	margin-right: calc(0px - calc(var(--gap) / 2));
}

.flex--column {
	display: flex;
	flex-direction: column;
}

.flex>* {
	margin-left: calc(var(--gap) / 2);
	margin-right: calc(var(--gap) / 2);
}

.flex.one>* {
	flex: 100% 0 0;
}

.flex.two>* {
	flex: calc(50% - var(--gap)) 0 0;
}

.flex.three>* {
	flex: calc(33% - var(--gap)) 0 0;
}

.flex.four>* {
	flex: calc(25% - var(--gap)) 0 0;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
	.flex.two>* {
		flex: calc(50% - var(--gap)) 0 0;
	}

	.flex.three>* {
		flex: calc(50% - var(--gap)) 0 0;
	}

	.flex.four>* {
		flex: calc(50% - var(--gap)) 0 0;
	}
}

@media screen and (max-width: 768px) {
	.flex.two>* {
		flex: 100% 0 0;
	}

	.flex.three>* {
		flex: 100% 0 0;
	}

	.flex.four>* {
		flex: 100% 0 0;
	}
}

/* END FLEX */